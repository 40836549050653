// import "@hotwired/turbo-rails"
import Blazy from "blazy"
// import Title from "../animations/title"
// import Paragraphs from "../animations/paragraphs.js"
import Grid from "./grid"
import Contact from "./contact"
import Gallery from "./gallery"
// import Snap from "./snap"
export default class Page {
    constructor() {
        // this.snap = new Snap()
        this.contact = new Contact()
        this.gallery = new Gallery()
        this.grid = new Grid()
        // this.title = new Title()
        // this.paragraphs = new Paragraphs()
        window.LOAD_EVENT.on('loaded', () => this.mount())
        // this.turbo()
    }

    // https://turbo.hotwired.dev/reference/events
    turbo() {
        document.addEventListener("turbo:click", async (event) => this.unmount())
        document.addEventListener("turbo:frame-load", async (event) => this.mount())
        // this.mounted()
    }

    mount() {
        gsap.set("#turbo-frame", {
            opacity: 0
        })
        gsap.to("#turbo-frame", {
            duration: .2,
            opacity: 1,
            onComplete: () => this.mounted()
        })
    }

    mounted() {
        this.blazy()
        // this.snap.reload()
        // this.title.reload()
        this.grid.reload()
        this.gallery.reload()
        // this.paragraphs.reload()
    }

    // 
    unmount() {
        this.destroy()
        //
        gsap.to("#turbo-frame", {
            duration: .15,
            opacity: 0,
            onComplete: () => this.unmounted()
        })
    }

    destroy() {
        // this.paragraphs.destroy()
        this.grid.destroy()
        this.gallery.destroy()
        // this.title.destroy()
        // this.snap.destroy()
    }

    unmounted() {
    }

    blazy() {
        return new Blazy({
            success: (el) => {
                console.log("img, video, loaded")
                el.parentElement.classList.add("loaded")
            }
        })
    }

}